import {Avatar, Button, Card, Container, Grid, Image, Spacer, Text} from "@nextui-org/react";
import {NotificationIcon} from "../components/NotificationIcon";
import _ from 'lodash'
import './App.sass'

import {useState} from "react";
import useContract from "../hooks/useContract";
import {useSpring, animated,} from "@react-spring/web";

const MockItem = ({ text }) => {
    const [count,setCount]=useState(1)

    return (
        <Card css={"background-image: url('/images/bg.png'); background-repeat: repeat-x;"} className={'App_mock'}>
            <Card.Body className={'App_mock-body'} >
                        <Image
                            src={"/images/bg.jpg"}
                            alt="Default Image"
                            width={1000}
                        />
                <Text h2>$gCAT: EQA6_S-r0DicTECPP9jU3rBTtkoG7r4v7uikJNzSI8uZN7QE</Text>
            </Card.Body>
            <Card.Body className={'App_mock-body'} >
                <Grid.Container gap={2} justify="center">
                    <Grid md={6}>
                        <div className={'introduce_left'}>
                            <Text h2>GrumpyCat
                            </Text>
                            <Text  weight="bold">
                            GrumpyCat is the newest meme coin in the TON ecosystem! GrumpyCat offers a fun way to explore TON before major liquidity arrives. Get ready to add a touch of attitude to your crypto portfolio!
                            </Text>
                        </div>
                    </Grid>
                    <Grid md={6}>
                        <Image
                            src={"/images/grame_gcat.png"}
                            alt="Default Image"
                            width={1000}
                        />
                    </Grid>
                </Grid.Container>
            </Card.Body>
        </Card>
    );
};

function App() {

  return (
    <div className="App">

        <Container justify={"center"}>
        <MockItem/>
        </Container>
        <Container justify={"center"}>
        </Container>

        <section style={{background: "#4f5299"}}>
            <Container justify={"center"}>
                <Grid.Container gap={2} justify="center">
                    <Grid md={8}>
                        <Grid.Container gap={2} justify="center">
                            <Grid md={6}>
                                <div style={{margin: "auto",textAlign: "center"}}>
                                    <Image src={"/images/logo.png"} width={150} height={50}/>
                                </div>
                            </Grid>
                            <Grid md={6}>
                                <div style={{margin: "auto",textAlign: "center"}}>
                                    <div style={{display: "flex", gap: "1rem"}}>
                                        <a href={"https://twitter.com/gcat_tg"}>
                                            <Avatar src={"/images/x.png"} size="sm"/>
                                        </a>
                                        <a href={"https://t.me/grumpycat_tg"}>
                                            <Avatar src={"https://play-lh.googleusercontent.com/ZU9cSsyIJZo6Oy7HTHiEPwZg0m2Crep-d5ZrfajqtsH-qgUXSqKpNA2FpPDTn-7qA5Q"} size="sm"/>
                                        </a>
                                    </div>
                                </div>
                            </Grid>
                        </Grid.Container>
                    </Grid>

                </Grid.Container>
            </Container>
        </section>

    </div>
  );
}

export default App;
