import Web3 from "web3";
import {useEffect, useLayoutEffect, useState} from "react";
import ABIContract from '../contracts/ABIContract.json'
let web3=null
let initConnected=false
let contract=null
if (typeof window !== 'undefined' && window.ethereum) {
    web3=new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_HOST_TESTNET))

    const newContract=new web3.eth.Contract(ABIContract,process.env.REACT_APP_CONTRACT_ADDRESS)
    contract=newContract
}
export default function useContract(){
    const [account,setAccount]=useState("")
    const [connected,setConnected]=useState(initConnected)
    useLayoutEffect(()=>{
        if(!connected){
            window.ethereum.request({
                method: 'eth_requestAccounts',
            }).then(res=>{setAccount(res[0])
                setConnected(true)

            })
            connectWalletHandler()
            initListeners()
        }
    },[])
    const getAccount=()=>{
        return account
    }
    const getContract=()=>{
        return contract
    }
    const connectWalletHandler =async () => {
        try {
            if (window.ethereum) {
                const networkId = await window.ethereum.request({
                    method: "net_version",
                });
                if (Number(networkId) === Number(process.env.REACT_APP_CHAIN_ID)) {
                    await accountChangedHandler()
                    setConnected(true)
                }
                else{
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: web3.utils.numberToHex(Number(process.env.REACT_APP_CHAIN_ID)) }], // chainId must be in hexadecimal numbers
                    });
                }

            } else {
                throw new Error("Not install wallet Metamask")
            }
        }catch (e){
            console.error(e.message)
            // alert(e.message)
        }
    }
    const accountChangedHandler = async () => {

        // const newContract=new web3.eth.Contract(contractABI,process.env.REACT_APP_CONTRACT_ADDRESS)
        // setContract(newContract)
        // setSetting({...setting,connect: true})
    }
    const initListeners = () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", () => {
                window.location.reload()
            });
            window.ethereum.on("chainChanged", () => {
                window.location.reload()
            });
        }
    };

    const actionAccountHandler=(action)=>{
        switch (action) {
            case 'copy_address':
                copyAddress()
                break
            case 'disconnect':
                disconnect()
                break
            default:

        }

    }

    const copyAddress=async ()=>{
        await navigator.clipboard.writeText(account)
    }

    const disconnect=()=>{
        setConnected(false)
    }

    return {account,contract,actionAccountHandler,connected,connectWalletHandler}
}