import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {createTheme, NextUIProvider} from "@nextui-org/react";
import NavbarSharbi from "./components/NavbarSharbi";
import useContract from "./hooks/useContract";
import {useTrail,animated} from "@react-spring/web";
const theme = createTheme({
    type: "light", // it could be "light" or "dark"
    theme: {
        colors: {
             primary: '#4ADE7B',
             secondary: '#F9CB80',
             error: '#FCC5D8',
        },
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));

function Root({children}){
    const [items,setItems]=useState(children)
    const [trails, api] = useTrail(
        items.length,
        () => ({
            from: { opacity: 0 },
            to: { opacity: 1 },
        }),
        []
    )
    return    <React.StrictMode><NextUIProvider theme={theme}>
        {
            trails.map((props,index)=><animated.div style={props}>
                {items[index]}
                </animated.div>)
        }
    </NextUIProvider></React.StrictMode>

}
root.render(

            <Root>
                <NavbarSharbi/>
                <App />
            </Root>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
