import {Navbar, useTheme, Image} from "@nextui-org/react";
import './NavbarSharbi.sass'

export default function NavbarSharbi() {
    const { isDark } = useTheme();
    return (
            <Navbar isBordered={isDark} variant="floating" >
                <Navbar.Brand>
                    <Image src={"/images/logo.png"} width={150} height={50}/>
                </Navbar.Brand>
                <Navbar.Content hideIn="xs" variant="underline">
                    <Navbar.Content hideIn="xs" variant="underline">
                    <Navbar.Link target="_blank" href="https://twitter.com/gcat_tg">Happy X </Navbar.Link>
                    <Navbar.Link target="_blank" href="https://t.me/grumpycat_tg">Excited Community</Navbar.Link>
                </Navbar.Content>
                </Navbar.Content>
                <Navbar.Content>
                </Navbar.Content>
            </Navbar>
    )
}
